.form-contact-skeleton {
  .form-contact-skeleton-map {
    width: 100%;
    height: 300px;

    > div {
      width: 100% !important;
      height: 100% !important;;
    }
  }

  .ant-skeleton-element {
    width: 100% !important;;
  }

  .ant-skeleton-input {
    width: 100% !important;
  }

  .ant-form-item-control {
    width: 100% !important;;
    display: block;
  }

  .ant-skeleton.ant-skeleton-element .ant-skeleton-input {
    min-width: auto;
  }
}
