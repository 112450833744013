// Ag Grid
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-balham.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
@import '~ag-grid-community/styles/ag-theme-material.css';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
}

.list-action {
  display: block;
  text-align: center;
  width: 100%;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-form-feedback {
  color: red;
  padding: 8px 0 0 0;
}

.ant-input-number {
  box-sizing: border-box;
  margin: 0;
  padding: 5px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  // font-family: 'Roboto Mono', monospace;
  // letter-spacing: 2px;
}

.ant-popover-open {
  // font-size: 12px !important;
}

@media print {
  .ant-layout {
    flex-direction: column !important;
  }

  .ant-layout-sider,
  .ant-pro-footer-bar {
    display: none;
  }
}

.wrapper-onboarding {
  width: 1024px;
  margin: 0 auto;

  @media (max-width: 1320px) {
    width: 100%;
  }
}
